<template>
  <!-- Contents -->
  <div v-show="testpass">
    <router-view
     :eventNo="Number(eventNo)"
     :eventInfo="eventInfo"
     :eventDate="Number(eventDate)"
     @refresh="onRefresh"
    ></router-view>
  </div>
  <!-- // CONTENTS -->
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';

export default {
  name: 'EventDetailNo',
  mixins: [mixinHelperUtils],
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      testpass: false,
      eventInfo: {
        event_detail: [],
      },
      eventDate: this.$moment().format('YYYYMM'),
    };
  },
  props: {
    /**
     * EventNo: 이벤트 항목
     */
    eventNo: {
      type: [String, Number],
      required: true,
    },
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    this.retrieveEvent();
  },
  watch: {
    // 라우트가 변경되면 메소드를 다시 호출
    // https://router.vuejs.org/kr/guide/essentials/dynamic-matching.html
    // https://router.vuejs.org/kr/guide/advanced/data-fetching.html
    $route: 'retrieveEvent',
  },
  methods: {
    retrieveEvent() {
      let url = `${APIs.EVENT_LIST_DETAIL}/${this.eventNo}`;
      if (this.userInfo) {
        url += `/?applyEmail=${this.userInfo.email}`
      }
      this.portalApiClient({
        url: url,
        method: 'get'
      }).then(({ data }) => {
        if (data.result) {
          if (data.data.deleteDate || !data.data.isShow) {
            this.$router.push({name: 'EventList'})
          } else {
            this.eventInfo = data.data;
            this.eventDate = data.data.startDate.replace(/./g, '').substr(0, 8);
            this.testpass = true;
          }
        } else {
          this.showAlert(data.message);
        }
      });
    },
    onRefresh() {
      this.retrieveEvent();
    },
  },

};
</script>
